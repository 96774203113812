export default {
	'en': [
		{
			description: "01 / Sign up for either Bithumb or Coinone."
		},
		{
			description: "02 / Please recharge your currency."
		},
		{
        	description: '03 / In the case of Bithumb and Coinone, <br>'
			+ '　　You can purchase DVI in both the KRW <br>'
			+ '　　market and the BTC market.'
        }
	]
}
